<template>
  <zy-monitor
    :channel-no="channelNo"
    :cameraIndexCode="cameraIndexCode"
    :quality="quality"
    :templateId="templateId"
  ></zy-monitor>
</template>

<script>
import ZyMonitor from "./components/zy-monitor"
export default {
  components: { ZyMonitor },
  data() {
    return {
      channelNo: null,
      cameraIndexCode: null,
      quality: 1,
      templateId: "simple",
    }
  },
  created() {
    const query = this.$route.query
    // query.cameraIndexCode = "J79407237"
    // query.channelNo = "1"
    this.channelNo = query.channelNo
    this.cameraIndexCode = query.cameraIndexCode
    this.quality = query.quality || 1
    this.templateId = query.templateId || "simple"
  },
}
</script>

<style></style>
