<template>
  <div
    class="zy-monitor"
    :id="videoId"
  ></div>
</template>

<script>
import EZUIKit from "ezuikit-js"
import { get } from "@/utils/request"
export default {
  data() {
    return {
      player: null,
      token: {},
    }
  },
  computed: {
    videoId() {
      return this.channelNo + "_" + this.cameraIndexCode + "_" + new Date().getTime()
    },
    url() {
      let hdFlag = ""
      if (this.quality == 1) {
        hdFlag = ".hd"
      }
      const url = `ezopen://open.ys7.com/${this.cameraIndexCode}/${this.channelNo}${hdFlag}.live`
      return url
    },
  },
  props: {
    channelNo: {
      type: Number | String,
    },
    cameraIndexCode: {
      type: Number | String,
    },
    quality: {
      type: Number | String,
    },
    templateId: {
      type: String,
      default: "simple",
    },
  },
  watch: {
    channelNo: {
      immediate: true,
      handler(val) {
        if (!this.player) {
          this.initPlayer()
          return
        }
        this.player.stop().then(() => {
          this.player.play(this.url)
        })
      },
    },
  },
  methods: {
    async getToken() {
      return new Promise((resolve, reject) => {
        get("/camera/getFluoriteToken")
          .then((res) => {
            const data = res.data || {}
            resolve(data.accessToken)
          })
          .catch((_) => {
            this.$message.error("获取监控token失败")
            reject()
          })
      })
    },
    async initPlayer() {
      const accessToken = await this.getToken()
      this.player = new EZUIKit.EZUIKitPlayer({
        id: this.videoId, // 视频容器ID
        width: this.$el.clientWidth,
        height: this.$el.clientHeight,
        accessToken: accessToken,
        template: this.templateId,
        url: this.url,
      })
    },
  },
  beforeDestroy() {
    this.player.stop()
    this.player = null
  },
}
</script>

<style lang="less" scoped>
.zy-monitor {
  width: 100%;
  height: 100%;
}
</style>
